@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: crashlanding;
  src: url(/fonts/CrashLandingBB.otf);
}

@font-face {
  font-family: collectem;
  src: url(/fonts/CollectEmNowBB-Reg.otf);
}

@font-face {
  font-family: graveside;
  src: url(/fonts/GravesideBB.otf);
}
canvas {
  background-color: transparent !important;
  border: none; /* Ensures no borders affect the look */
  outline: none; /* Removes any outlines */
}

#unity-canvas {
  background: none !important; /* Forces no background on the canvas */
}
@layer base {
  body {
    background-image: url("https://arenaboyz.blob.core.windows.net/$web/Static/background.png");
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  h1 {
    @apply font-crashlanding text-2xl;
  }
  
  h2 {
    @apply text-xl;
  }
  
  h3 {
    @apply text-lg;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .btn {
    @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded;
  }
  
  .btn-orange {
    @apply bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded;
  }
  
  .btn-green {
    @apply bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded;
  }
  
  .btn-red {
    @apply bg-red-500 hover:bg-red-700 disabled:opacity-50 text-white font-bold py-2 px-4 rounded;
  }
  
  .highlight {
    border: 2px solid #4CAF50; /* Green border for highlighting */
    box-shadow: 0 0 10px #4CAF50; /* Optional: adds a glow effect */
  }
}

@layer utilities {
  @variants responsive {
    .text-shadow {
      text-shadow: 2px 2px black;
    }
    
    .text-shadow-md {
      text-shadow: 4px 4px black;
    }
    
    .text-shadow-lg {
      text-shadow: 15px 15px black;
    }
    
    .text-shadow-none {
      text-shadow: none;
    }
  } 
}
